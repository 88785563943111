<template>
  <v-layout column>
    <LabelDateField
      label="Data terminacji"
      placeholder="Wybierz..."
      :value.sync="value.date"
      :disabled="disabled"
      noMargins
      hide-details
    ></LabelDateField>
    <LabelTextField
      label="Tydzień ciąży"
      class="mt-3"
      placeholder="Wpisz..."
      v-mask="'##'"
      :value.sync="value.week"
      :disabled="disabled"
      noMargins
      hide-details
    ></LabelTextField>
    <v-layout column class="mt-3">
      <DefaultSubtitle class="mb-3">Rodzaj terminacji</DefaultSubtitle>
      <v-radio-group
        v-model="value.pregnancy_type"
        :class="['mt-1 zg-text-control']"
        :row="!$vuetify.breakpoint.smAndDown"
        :disabled="disabled"
        hide-details
      >
        <v-radio
          class="zg-text-control"
          label="Łyżeczkowanie"
          :value="0"
        ></v-radio>
        <v-radio
          class="zg-text-control"
          label="Tabletka poronna"
          :value="1"
        ></v-radio>
      </v-radio-group>
    </v-layout>
    <DefaultSubtitle class="my-3">Powód</DefaultSubtitle>
    <HTMLTextArea
      :value.sync="value.reason"
      :disabled="disabled"
      placeholder="Wpisz..."
      dense
      hideLabel
    ></HTMLTextArea>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),    LabelDateField: () => import("@/components/LabelDateField"),
    LabelTextField: () => import("@/components/LabelTextField"),
    HTMLTextArea: () => import("@/components/inputs/HTMLTextArea"),
  },
};
</script>

<style scoped>
.zg-text-control ::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
</style>